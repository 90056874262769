import React, { useState } from "react";
import "./Galerija.css";
import { FaTimes } from "react-icons/fa";
import { Helmet } from "react-helmet";
import GalerijeList from "./GalerijeList";

const Galerije = (props) => {
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const Galerije1 = props.Galerije1;
  const Naslov = props.Naslov;
  const title = props.title; // New title prop

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GalerijeList
        GALERIJA={Galerije1}
        model={model}
        setModel={setModel}
        tempimgSrc={tempimgSrc}
        getImg={getImg}
        Naslov={Naslov}
      />
    </>
  );
};

export default Galerije;
