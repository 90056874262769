import React, { Component } from "react";
import "./Countdown.css";

class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    // Set the target date
    const targetDate = new Date("2023-12-09T16:00:00").getTime();

    // Calculate initial time remaining
    const currentTime = new Date().getTime();
    const timeRemaining = Math.max(targetDate - currentTime, 0);

    this.state = {
      targetDate,
      timeRemaining,
    };
  }

  componentDidMount() {
    this.timerId = setInterval(this.updateCountdown, 1000);
  }

  updateCountdown = () => {
    const currentTime = new Date().getTime();
    const { targetDate } = this.state;
    const timeRemaining = Math.max(targetDate - currentTime, 0);

    this.setState({ timeRemaining });

    if (timeRemaining === 0) {
      clearInterval(this.timerId);
    }
  };

  render() {
    const { timeRemaining } = this.state;

    // Calculate days, hours, minutes, and seconds from timeRemaining
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return (
      <div className="timer">
        {days} dana : {hours} sati : {minutes} minuta : {seconds} sekundi
      </div>
    );
  }
}

export default CountdownTimer;
