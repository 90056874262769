import React from "react";
import "./Card.css";

const CardNagrade = ({ title, content, imageUrl }) => {
  return (
    <div className="cardNagrade">
      <div className="card-content">
        <h2 className="card-title card-title-nagrade">{title}</h2>
        <img src={imageUrl} className="card-image" />
        <p className="card-text-nagrade">{content}</p>
      </div>
    </div>
  );
};

export default CardNagrade;
