import React from "react";
import "./Organizatori.css"; // Uvezite svoj stil
import { OrgTim2018 } from "./StariOrg/OrgTim2018.js";
import { OrgTim2019 } from "./StariOrg/OrgTim2019.js";
import { OrgTim2020 } from "./StariOrg/OrgTim2020.js";
import { OrgTim2021 } from "./StariOrg/OrgTim2021.js";
import { OrgTim2022 } from "./StariOrg/OrgTim2022.js";
import OrganizatoriList from "./OrganizatoriList";

const ikonice = false;

const Organizatori = () => {
  return (
    <div className="organizatori">
      <OrganizatoriList OrgTim={OrgTim2022} ikonice={ikonice} />
      <OrganizatoriList OrgTim={OrgTim2021} ikonice={ikonice} />
      <OrganizatoriList OrgTim={OrgTim2020} ikonice={ikonice} />
      <OrganizatoriList OrgTim={OrgTim2019} ikonice={ikonice} />
      <OrganizatoriList OrgTim={OrgTim2018} ikonice={ikonice} />
    </div>
  );
};

export default Organizatori;
