import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io"; // Importovanje ikonica

const Pitanja = ({ elementsData }) => {
  const [showText, setShowText] = useState(
    Array(elementsData.length).fill(false)
  );

  const toggleText = (index) => {
    const updatedShowText = [...showText];
    updatedShowText[index] = !updatedShowText[index];
    setShowText(updatedShowText);
  };

  return (
    <div className="pitanja-container">
      {elementsData.map((element, index) => (
        <div key={index} className="pitanje">
          <h3
            className="glavniTekst"
            onClick={() => toggleText(index)}
            style={{
              cursor: "pointer",
              fontSize: "1.1rem",
              marginBottom: "0.5rem",
            }}
          >
            {showText[index] ? <IoIosArrowForward /> : <IoIosArrowDown />}{" "}
            {element.title}
          </h3>
          {showText[index] && (
            <p className="skriveniTekst">{element.additionalText}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Pitanja;
