import Meetup1 from "./MeetupSlike/meetup1.JPG";
import Meetup2 from "./MeetupSlike/meetup2.JPG";
import Meetup3 from "./MeetupSlike/meetup3.JPG";
import Meetup4 from "./MeetupSlike/meetup4.JPG";
import Predavac1Img from "./MeetupSlike/PhotoJV.jpg";
import Predavac2Img from "./MeetupSlike/PM.jpg";
import Predavac3Img from "./MeetupSlike/SM.jpg";

export const meetupData = [
  {
    naslov: "Make IT Learn MeetUp",
    tekst: (
      <div>
        <p>
          Make IT Learn MeetUp predstavlja dodatni sadržaj AIBG-a koji se
          sastoji od predavanja na različite i aktuelne teme iz sveta veštačke
          inteligencije i mašinskog učenja.
        </p>
        <p>
          Predavanja su otvorena za sve studente tehničko-tehnoloških,
          prirodno-matematičkih fakulteta i viših škola, a predavači su najčešće
          ljudi sa iskustvom iz industrije i profesori ili asistenti sa
          Univerziteta, koji doprinose boljem kvalitetu samog sadržaja.
        </p>
        {/* <p>
          Predavanjima mogu prisustvovati svi zainteresovani studenti i ona će
          se održati 20. novembra. Neophodno je da se prijavite za dolazak na
          Make IT Learn MeetUp, a prijave traju od 8. novembra do 19. novembra.
          Ove godine smo vam pripremili nove teme i nove predavače, nešto više o
          njima možete pročitati u nastavku.
        </p> */}
        <p>Predavanju su održana 20. novembra u zgradi TMF-a.</p>
        <p>Vidimo se iduće godine!</p>
      </div>
    ),
    predavaci: [
      {
        ime: "Dr Jelena Vasiljević: Primene alata veštačke inteligencije u medicini i bioinformatici",
        slika: Predavac1Img,
        opis: "Dr Jelena Vasiljević je doktor nauka Elektrotehničkog fakulteta u Beogradu, vanredni profesor na Računarskom fakultetu više od 10 godina, radila je 19 godina u Institutu Mihajlo Pupin kao viši naučni saradnik. Dobitnik je mnogih nagrada i priznanja za doktorat i naučne projekte iz primena neuronskih mreža i fraktala na medicinske slike u dijagnostici i terapiji kancera. Nalazi se u Oxfordskoj enciklopediji Uspešnih Srba. Predaje izmedju ostalih predmeta i Primenjenu veštačku inteligenciju, Obradu medicinskih slika, Neuronske mreže, Genetske algoritme.",
      },
      {
        ime: "Pavle Milošević: Primena veštačke inteligencije za bezbednost na radu: automatska detekcija zaštitne opreme",
        slika: Predavac2Img,
        opis: "Pavle Milošević je docent Univerziteta u Beogradu - Fakultet organizacionih nauka. Autor je preko 80 publikacija, objavljenih u časopisima ili prezentovanih na naučnim i stručnim skupovima, kao i 2 fakultetska udžbenika. Za naučni rad je višestruko nagrađivan, pri čemu su najznačajnije Godišnja nagrada Matematičkog instituta SANU u oblasti račinarstva za doktorsku tezu i studentski grant Evropske asocijacije za fazi logiku. Ima višegodišnje iskustvo na istraživačkim i komercijalnim projektima u oblasti veštačke inteligencije. Član je vodećih stručnih asocijacija: European Society for Fuzzy Logic and Technology (EUSFLAT) i Serbian AI Society (SAIS). Njegove glavne oblasti istraživanja i naučnog rada su: računarska inteligencija, mašinsko učenje, metaheuristike, analiza vremenskih serija i klasična teorija upravljanja.",
      },
      {
        ime: "Slobodan Marković: Srpski jezik u doba veštačke inteligencije",
        slika: Predavac3Img,
        opis: (
          <div>
            {" "}
            <p>
              Slobodan Marković radi kao savetnik za digitalne tehnologije u
              Programu Ujedinjenih nacija za razvoj (UNDP). Jedan je od
              pokretača inicijative za razvoj otvorenih NLP/NLU resursa i alata
              za srpski jezik. Na Make IT Learn Meetup će govoriti o ovoj
              inicijativi i zašto svi zajedno moramo da uložimo mnogo više kako
              bismo osigurali poziciju srpskog jezika u doba revolucije veštačke
              inteligencije.
            </p>
            <p>
              Slobodan već 25 godina aktivno učestvuje u oblikovanju IKT
              politika u Srbiji. Radio je u Ministarstvu telekomunikacija i
              informacionog društva, gde je savetovao ministarku o
              liberalizaciji tržišta telekomunikacija, elektronskoj upravi i
              digitalnom poslovanju. Godine 2001. osnovao je prvu domaću
              organizaciju posvećenu promovisanju otvorenog interneta, a tokom
              godina učestvovao je u radu globalnih formuma za upravljanje
              internetom, kao što su ICANN i IGF. ",
            </p>
          </div>
        ),
      },
      // Možete dodati više predavača ako je potrebno
    ],
    slike: [
      { src: Meetup1 },
      { src: Meetup2 },
      { src: Meetup3 },
      { src: Meetup4 },
      // Dodajte ostale slike
    ],
  },
  // Dodajte ostale stavke za druga predavanja ako ih ima
];
