export const elementsData = [
  {
    title: "Ko može da učestvuje na takmičenju?",
    additionalText:
      "Na takmičenju mogu učestvovati samo studenti tehničko-tehnoloških i prirodno-matematičkih fakulteta, državnih i privatnih univerziteta u Beogradu, starosti od 18 do 26 godina.",
  },
  {
    title: "Koji programski jezik mogu da koristim?",
    additionalText:
      "Može se koristiti bilo koji programski jezik koji podržava korišćenje API poziva.",
  },
  {
    title: "Kako izgleda selekcioni proces?",
    additionalText:
      "Selekcija će se vršiti na osnovu poslate prijave uz priložen CV, projekata na GitLab-u illi GitHub-u i selekcionog zadatka koji će biti poslat prijavljenim kandidatima nakon zatvaranja prijava.",
  },
  {
    title: "Da li je neophodno ostaviti svoj CV?",
    additionalText:
      "Neophodno je da prilikom prijave svi potencijalni takmičari ostave svoj CV.",
  },
  {
    title: "Da li takmičari dobijaju pomoć u toku takmičenja?",
    additionalText:
      "Takmičarima će u svakom trenutku biti dostupan neko od organizatora ukoliko imaju pitanja ili tehničkih poteškoća. Konsultacije van okvira tima i organizatora nisu dozvoljene.",
  },
];
