import "./HomeStyles.css";
import logoAIBG from "./SlikeHome/logo-white.png";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import Card from "./Cards/Card.js";
import CardNagrade from "./Cards/CardNagrade.js";
import Pokrovitelji from "./Cards/Pokrovitelji";
import { cardData } from "./Cards/cardData";
import { cardNagradeData } from "./Cards/cardNagradeData";
import { PokroviteljiData } from "./Cards/PokroviteljiData.js";
import Pitanja from "./Cards/Pitanja";
import { elementsData } from "./Cards/elementsData";

function Home() {
  return (
    <div className="home-container">
      <div className="home">
        <div className="vuk">
          <h2 className="naslov">ARTIFICIAL INTELLIGENCE BATTLEGROUND 6.0</h2>
          <img src={logoAIBG} alt="Logo AI BattleGround" className="LogoHome" />
          <h2 className="datum">9. I 10. DECEMBAR</h2>
          <h1 className="longnight">
            THE LONG NIGHT IS <span className="aibgboja">COMING...</span>
          </h1>
        </div>

        <div className="timer timer-slika">
          <div className="timerrazmak"></div>
          <h2>TAKMIČENJE POČINJE ZA</h2>
          <CountdownTimer />
          {/* <a href="/prijava" className="razmakTakmicenje">
            <button className="dugme">PRIJAVI SE</button>
          </a> */}
          <div className="timerrazmak"></div>
          <div className="timerrazmak"></div>
          <div className="timerrazmak"></div>
        </div>

        <div className="pozadina2">
          <div className="card-container">
            {cardData.map((card) => (
              <Card
                title={card.title}
                content={card.content}
                button={card.button}
              />
            ))}
          </div>
        </div>

        <div className="timerrazmak"></div>
        <div className="pokrovitelj-naslov">
          <h2 className="pokrovitelj-naslov-tekst">NAGRADE</h2>
        </div>
        <div className="cardNagrade-container">
          {cardNagradeData.map((cardNagrade) => (
            <CardNagrade
              title={cardNagrade.title}
              content={cardNagrade.content}
              imageUrl={cardNagrade.imageUrl}
            />
          ))}
        </div>

        <div className="razmakhome"></div>

        <div className="pokrovitelj-naslov">
          <h2 className="pokrovitelj-naslov-tekst">POKROVITELJI</h2>
        </div>
        <div className="pokrovitelji">
          {PokroviteljiData.map((pokroviteljiCard, index) => (
            <Pokrovitelji key={index} imageUrl={pokroviteljiCard.imageUrl} />
          ))}
        </div>

        <div className="pozadina2">
          <div className="cestoPostavljenaPitanja">
            <h2 className="pitanjaNaslov">ČESTO POSTAVLJENA PITANJA</h2>
            <Pitanja elementsData={elementsData} />
            <p className="dodatnoKO">
              Ukoliko imate neko pitanje za nas, obratite nam se putem formе na
              stranici{" "}
              <a className="linkKO" href="/kontakt">
                Kontakt
              </a>{" "}
              ili nekom od{" "}
              <a href="/organizatori" className="linkKO">
                Organizatora
              </a>
              .
            </p>
          </div>
          <div className="razmakhome"></div>
        </div>
      </div>
    </div>
  );
}

export default Home;
