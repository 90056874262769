// PravilaTakmicenjaData.js
export const pravilaTakmicenjaData = [
  {
    tekst:
      "AIBG Belgrade – Artificial Intelligence BattleGround Belgrade je takmičenje koje organizuje Udruženje studenata tehnike Evrope – Beograd u saradnji sa kompanijama partnerima projekta. ",
  },
  {
    tekst:
      "AIBG Belgrade se održava u skladu sa unapred defnisanim pravilima kojima se regulišu prava, obaveze i odgovornosti učesnika i organizatora. Kako bismo ovo takmičenje sproveli na najbolji mogući način i kako bismo omogućili što prijatnije iskustvo svim takmičarima, molimo Vas da pročitate pravila koja će se primenjivati na sve učesnike kao i na organizatore.",
  },
  {
    naslov: "Prijave",
    tekst:
      "1. Na takmičenju mogu učestvovati samo studenti tehničko-tehnoloških i prirodno-matematičkih fakulteta, državnih i privatnih univerziteta u Beogradu, starosti od 18 do 26 godina.",
  },
  {
    tekst:
      "2. Na takmičenju ne mogu učestvovati koordinatori na projektu, članovi Udruženja studenata tehnike Evrope – Beograd, kao ni zaposleni i saradnici u kompanijama partnerima projekta.",
  },
  {
    tekst:
      "3. Prijavljivanje takmičara je dozvoljeno isključivo u periodu kada su prijave za učešće otvorene, odnosno od 08. do 24. Novembra.",
  },
  {
    tekst:
      "4. Prijave mogu biti timske i pojedinačne." +
      "-Tim može imati 3 ili 4 člana. Prilikom prijavljivanja, neophodno je navesti sve članove tima." +
      "-Takmičare koji su se prijavili pojedinačno, organizacioni tim će rasporediti u novoformirane timove u skladu sa mogućnostima.",
  },
  {
    naslov: "Selekcija",
    tekst:
      "1. Organizacioni tim zadržava diskreciono pravo da ne prihvati prijavu odredjenog tima ili pojedinca. Prednost tokom selekcionog procesa će imati studenti završnih godina koji nisu u radnom odnosu dužem od šest meseci.",
  },
  {
    tekst:
      "2. Nakon zatvaranja prijava organizacioni tim će izvršiti selekciju. ",
  },
  {
    tekst:
      "3. Selekcija će se vršiti na osnovu popunjene forme za prijavu uz priložen CV i link ka projektima na GitLab-u ili GitHub-u.",
  },
  {
    tekst: "4. Na takmičenju učestvuje 16 timova.",
  },
  {
    naslov: "Takmičenje",
    tekst: "1. Takmičenje se održava 09. i 10. decembra u Beogradu.",
  },
  {
    tekst:
      "2. Svi članovi tima moraju da budu prisutni za vreme otvaranja projekta i prezentacije zadatka. Oni koji ne budu prisutni biće diskvalifikovani. ",
  },
  {
    tekst:
      "3. Ukoliko neko od članova tima nije prisutan, a brojno stanje u tom trenutku čine tri osobe, tim može da pristupi takmičenju.",
  },
  {
    tekst:
      "4. Članovima tima nije dozvoljeno napuštanje prostora tokom celokupnog trajanja takmičenja.",
  },
  {
    tekst:
      "5. Od takmičara se očekuje pristojno ponašanje i poštovanje prema ostalim takmičarima i organizacionom timu.",
  },
  {
    tekst:
      "6. Takmičari mogu koristiti bilo koji programski jezik koji podržava korišćenje API poziva.",
  },
  {
    tekst:
      "7. Celokupno rešenje mora biti napravljeno za vreme trajanja takmičenja.",
  },
  {
    tekst:
      "8. Vreme za izradu rešenja je 20 časova. U okviru 20 časova timovi su dužni da okače svoja rešenja na WeTransfer i link pošalju putem forme koja će im biti prosleđena.",
  },
  {
    tekst:
      "9. Zajedno sa rešenjem od timova se očekuje kratko uputstvo (dokumentacija) za pokretanje rešenja.",
  },
  {
    tekst:
      "10. Takmičarima će u svakom trenutku biti dostupan neko od organizatora ukoliko imaju pitanja ili tehničkih poteškoća.",
  },
  {
    tekst: "11. Konsultacije van okvira tima i organizatora nisu dozvoljene.",
  },
  {
    tekst:
      "12. akmičarima nije dozvoljena izmena rešenja nakon isteka vremena. Međutim, ukoliko na početku borbe program nekog od timova prikaže grešku, taj tim ima mogućnost da ispravi svoje rešenje u roku od 10 minuta i pošalje ga nazad. Ako tim ne pošalje ispravljenu verziju u dogovorenom vremenu, smatra se diskvalifikovanim.",
  },
  {
    tekst:
      "13. Ukoliko u toku borbe program nekog od timova prikaže grešku prilikom izvršavanja, prednost će biti data timovima sa funckionalnim rešenjem.",
  },
  {
    tekst: "14. Takmičenje se odvija u dve faze:",
  },
  {
    tekst:
      "-Grupna faza, gde iz četiri grupe od po četiri tima izlazi po jedan pobednički tim koji nastavlja takmičenje.",
  },
  {
    tekst:
      "-Finalna borba, gde se pobednici grupne faze međusobno bore za prva tri mesta.",
  },
  {
    tekst:
      "15. Prva tri mesta osvajaju novčane nagrade (prvo mesto osvaja 100 000, drugo 50 000, a treće 25 000 dinara).",
  },
  {
    naslov: "AIBG WarmUp",
    tekst: "1. WarmUp se održava online.",
  },
  {
    tekst: "2. AIBG WarmUp je niz uvodnih aktivnosti uoči samog takmičenja. ",
  },
  {
    tekst:
      "3. Namenjen je takmičarima kako bi se upoznali sa AIBG-om, ostalim takmičarima i pripremili za vikend koji je pred njima.",
  },
  {
    naslov: "Saglasnost",
    tekst:
      "1. Takmičari su saglasni da se njihovi lični podaci, kao što su ime i prezime, fotografije i video zapisi sa njihovim likom mogu koristiti samo u svrhe promocije AIBG-a.",
  },
  {
    tekst:
      "2. Takmičari su saglasni da njihovi lični podaci (CV) budu prosleđeni kompanijama koje su podržale takmičenje.",
  },
  {
    tekst:
      "3. Takmičari su saglasni da organizator takmičenja ima pravo na finalni kod takmičara.",
  },
  // Dodajte više pravila ovde po istom obrascu
];
