import MO from "./SlikeStariOrg/MO2021.jpg";
import TOPIC from "./SlikeStariOrg/TOPIC2021.jpg";
import PR from "./SlikeStariOrg/PR2021.jpg";
import FR from "./SlikeStariOrg/FR2021.jpg";
import LOG from "./SlikeStariOrg/LOG2021.jpg";
import IT from "./SlikeStariOrg/IT2021.jpg";
import DESIGN from "./SlikeStariOrg/PUB2021.jpg";

export const OrgTim2021 = [
  {
    naslov5: "Organizacioni tim 2021",
    slikaUrl: MO,
    imeprezime: "Ana Stevanović",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    imeprezime: "Branislav Dragićević",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    imeprezime: "Mila Simić",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    imeprezime: "Veljko Nešić",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    imeprezime: "Natalija Branković",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    imeprezime: "Lenka Milošević",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    imeprezime: "Sara Tešić",
    pozicija: "KOORDINATOR ZA PUBLIKACIJE",
  },
];
