export const MenuData = [
  // {
  //   title: "Prijavi se",
  //   url: "/prijava", // URL ka stranici za "Prijavi se"
  //   cName: "nav-links-white",
  // },
  {
    title: "Takmičenje",
    cName: "nav-links",
    hasSubMenu: true,
    subMenu: [
      {
        title: "O takmičenju",
        url: "/otakmicenje", // URL ka stranici za "O takmičenju"
      },
      {
        title: "Pravila takmičenja",
        url: "/pravila", // URL ka stranici za "Pravila takmičenja"
      },
      {
        title: "MeetUp",
        url: "/meetup", // URL ka stranici za "Meetup"
      },
      // {
      //   title: "Prijavi se",
      //   url: "/prijava", // URL ka stranici za "Prijavi se"
      // },
    ],
  },
  {
    title: "Vesti",
    cName: "nav-links",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Novosti",
        url: "/vesti", // URL ka stranici za "Novosti"
      },
      {
        title: "Edu kutak",
        url: "/edukutak", // URL ka stranici za "Edukutak"
      },
    ],
  },
  // {
  //   title: "Galerija",
  //   cName: "nav-links",
  //   hasSubMenu: true,
  //   subMenu: [
  //     // {
  //     //   title: "AIBG 1.0",
  //     //   url: "/galerije/AIBG1.0", // URL ka stranici za "AIBG 1.0"
  //     // },
  //     // {
  //     //   title: "AIBG 2.0",
  //     //   url: "/galerije/AIBG2.0", // URL ka stranici za "AIBG 2.0"
  //     // },
  //     // {
  //     //   title: "AIBG 3.0",
  //     //   url: "/galerije/AIBG3.0", // URL ka stranici za "AIBG 1.0"
  //     // },
  //     // {
  //     //   title: "AIBG 4.0",
  //     //   url: "/galerije/AIBG4.0", // URL ka stranici za "AIBG 2.0"
  //     // },
  //     // {
  //     //   title: "AIBG 5.0",
  //     //   url: "/galerije/AIBG5.0", // URL ka stranici za "AIBG 1.0"
  //     // },
  //     // Dodajte ostale verzije galerije sa odgovarajućim URL-ovima
  //   ],
  // },
  {
    title: "Partneri",
    url: "/partneri",
    cName: "nav-links",
  },
  {
    title: "Organizatori",
    url: "/organizatori",
    cName: "nav-links",
  },
  {
    title: "Kontakt",
    url: "/kontakt",
    cName: "nav-links",
  },
  {
    title: "Igraj se",
    url: "https://aibgame.best.rs/login.php",
    cName: "nav-links",
  },
];
