import React from "react";
import "./PojedinacneVesti.css";
import { FaCalendarAlt, FaUser } from "react-icons/fa";

const PojedinacneVesti = ({ pojedinacnaVest }) => {
  return (
    <div className="pojedinacna-vest">
      <div className="naslov-vest">
        <h1 className="nalov-vesti">{pojedinacnaVest.naslov}</h1>
      </div>
      <div className="datum-autor-pozicija">
        <p className="datum-autor">
          <FaCalendarAlt />
          {pojedinacnaVest.datum}
        </p>
        <p className="datum-autor">
          <FaUser />
          Autor: {pojedinacnaVest.autor}
        </p>
      </div>
      <div className="pojedinacna-vest-bg">
        <div className="pozadina-siva">
          <img
            className="pozadina-slike"
            src={pojedinacnaVest.slika}
            alt={pojedinacnaVest.naslov}
          />
        </div>
      </div>
      <div className="sadrzaj">
        <p>{pojedinacnaVest.ceoSadrzaj}</p>

        <div className="slikavideo">
          <div className="nova-slika">
            {pojedinacnaVest.novaSlika && (
              <img
                src={pojedinacnaVest.novaSlika}
                className="slika-vest"
                alt="Nova slika"
              />
            )}
          </div>
          <div className="tekst-vest">
            <p>{pojedinacnaVest.dodatniTekst}</p>
          </div>
          <div className="video">
            {pojedinacnaVest.video && (
              <iframe
                title="Video"
                width="560"
                height="315"
                src={pojedinacnaVest.video}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div className="footer-razmak"></div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default PojedinacneVesti;
