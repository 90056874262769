import React, { useState } from "react";
import "./Meetup.css";
import { meetupData } from "./MeetupData.js";
import { Link } from "react-router-dom";

const Meetup = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    if (currentSlide < meetupData[0].slike.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div className="meetup">
      <div className="header-razmak"></div>

      {meetupData.map((item, index) => (
        <div className="contentMeetup" key={index}>
          <div className="naslov-centar">
            <h1 className="naslovStranice">{item.naslov}</h1>
          </div>
          <p className="text">{item.tekst}</p>
          <div className="noviDiv">
            {item.predavaci.map((predavac, idx) => (
              <div className="predavac" key={idx}>
                <div className="predavacInfo">
                  <h2 className="naslov-centar">{predavac.ime}</h2>
                  <p className="text">{predavac.opis}</p>
                </div>
                <img
                  src={predavac.slika}
                  alt={predavac.ime}
                  className="predavacSlika"
                />
              </div>
            ))}
          </div>
          <div className="slideshow-container">
            <button onClick={prevSlide} className="prev">
              &#10094;
            </button>
            <img
              src={item.slike[currentSlide].src}
              className="slideshow-img"
              alt="Meetup"
            />
            <button onClick={nextSlide} className="next">
              &#10095;
            </button>
          </div>
          <div className="footer-razmak"></div>
        </div>
      ))}
      {/* <div className="dugmePozicija">
        <Link to="/meetup-prijava">
          <button className="dugme">Prijavi se za Meetup</button>
        </Link>
      </div> */}
      <div className="footer-razmak"></div>
    </div>
  );
};

export default Meetup;
