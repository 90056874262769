import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const PrijavaLog = () => {
  const form = useRef();
  const [message, setMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_eczycey",
        "template_hukft5y",
        form.current,
        "Nnua2ZMR3r6lAITQZ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage({
            type: "success",
            text: "SKRRRRR :))) spešno ste se prijavili za LOG-i tim!",
          });
        },
        (error) => {
          console.log(error.text);
          setMessage({
            type: "error",
            text: "BRRRRRR :/// glup si ko toчak i nisi se dobro prijavio.",
          });
        }
      );

    e.target.reset();
  };
  return (
    <div className="meetup-container">
      <div className="header-razmak"></div>
      <div className="meetup-form">
        <div className="naslov-centar">
          <h1 className="naslovStranice">Пријава за логистички тим AIBG-a</h1>
        </div>
        <div className="meetup-prijave-tekst">
          <p>
            Хеј драги бестовци, добродошли у предворје логистичког тима. Све што
            је потребно за прелазак преко прага је попуњавање ове форме након
            чега ћете имати прилику да учествујете у организацији најлепше и
            најдуже ноћи која долази.
            <p>
              За 30 сати колико траје пројекат (9. и 10. децембар) надгледаћемо
              и услуживати такмичаре, размештати учионице, коментарисати саме
              борбе, али се и међусобно дружити, играти стони фудбал и остале
              друштвене игре, уживати у ФР-ованој роби...
            </p>
            <p>Тимови за које се можете пријавити су:</p>
            <li>
              Мултитаскери – стуб самог пројекта, раде све послове од дочекивања
              такмичара, преко размештања учионица до надгледања такмичара током
              њихове израде кода
            </li>
            <li>
              Коментатори – Аца Стојановић и Слободан Шаренац АИБГ-а, имаће
              прилику да дочарају и подигну борбе на још интензивнији ниво
            </li>
            <li>
              Фотографи – ту су да овековече сваки тренутак АИБГ-а и да
              Инстаграм улепшају својим сликама
            </li>
            <p>Рок за пријаву 3.12. до 23:59</p>
            <p>
              <br />
              Очекујемо вас у чопоруууууу
            </p>
          </p>
        </div>
        <form ref={form} onSubmit={sendEmail} className="meetup-left">
          <div className="form-grupa">
            <label>* Име и презиме:</label>
            <input type="text" name="ime" required />
          </div>
          <div className="form-grupa">
            <label>* Eмајл (БЕСТ-ов):</label>
            <input type="text" name="email" required />
          </div>
          <div className="form-grupa">
            <label>* Број мобитела:</label>
            <input type="text" name="telefon" placeholder="06..." required />
          </div>
          <div className="form-grupa radio-grupa">
            <label className="fakultet">* Теам(ови) у којима ћу радити:</label>
            <div className="radio-red">
              <label className="radio-label">
                <input type="checkbox" name="tim" value="multitaskeri" />
                Мултитаскери
              </label>
              <label className="radio-label">
                <input type="checkbox" name="tim" value="komentatori" />
                Коментатори
              </label>
              <label className="radio-label">
                <input type="checkbox" name="tim" value="fotografi" />
                Фотографи
              </label>
            </div>
          </div>
          <div className="form-grupa">
            <label>Ђе лог координатор да слави нову годину?!</label>
            <input type="text" name="bulsit" />
          </div>
          <div className="form-grupa">
            <label>Ко ментар:</label>
            <input type="text" name="komentar" />
          </div>
          <button>Шаљи</button>
          {message && (
            <div className={`message ${message.type}`}>{message.text}</div>
          )}
        </form>
      </div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default PrijavaLog;
