import React from "react";
import "./Otakmicenju.css"; // Uvezite svoj stil
import { otakmicenjuData } from "./OtakmicenjuData"; // Uvezite podatke o stranama

const Otakmicenju = () => {
  return (
    // <div className="pozadina">
      <div className="otakmicenje">
        <div className="header-razmak"></div>

        <div className="sections-container">
          <div className="naslov-centar">
            <h1 className="naslovStranice">O TAKMIČENJU</h1>
          </div>
          {otakmicenjuData.map((page, index) => (
            <div className="section" key={index}>
              <div className="content">
                <img
                  src={page.imageUrl}
                  alt={`Slika ${index + 1}`}
                  className="image"
                />
                <div className="text">
                  <p>{page.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="footer-razmak"></div>
      </div>
    // </div>
  );
};

export default Otakmicenju;
