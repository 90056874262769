import MO from "./SlikeOrganizatori/VaskeEA24.jpg";
import TOPIC from "./SlikeOrganizatori/Nince.jpg";
import PR from "./SlikeOrganizatori/Ena.jpg";
import FR from "./SlikeOrganizatori/Shiljko.jpg";
import LOG from "./SlikeOrganizatori/Dzordi.jpg";
import IT from "./SlikeOrganizatori/Faramir175.jpg";
import DESIGN from "./SlikeOrganizatori/Stejsi.jpg";

export const organizatoriData = [
  {
    naslov5: "Organizacioni tim 2023",
    slikaUrl: MO,
    linkedin: "https://www.linkedin.com/in/vasilijegogic/",
    email: "aibg.mo@best.rs",
    telefon: " +381 (0)61 11 75 338",
    imeprezime: "VASILIJE GOGIĆ",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    linkedin: "https://www.linkedin.com/in/nikolina-vranković-33b725224/",
    email: "aibg.top@best.rs",
    telefon: "+381 (0)62 82 65 850",
    imeprezime: "NIKOLINA VRANKOVIĆ",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    linkedin: "https://www.linkedin.com/in/aleksandra-radovanovic-64132b248/",
    email: "aibg.pr@best.rs",
    telefon: "+381 (0)63 88 75 559",
    imeprezime: "ALEKSANDRA RADOVANOVIĆ",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    linkedin: "https://www.linkedin.com/in/dimitrije-siljkovic-625083265/",
    email: "aibg.cr@best.rs",
    telefon: "+381 (0)62 30 53 35",
    imeprezime: "DIMITRIJE SILJKOVIĆ",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    linkedin: "https://www.linkedin.com/in/никола-ранчић-21361a233/",
    email: "aibg.log@best.rs",
    telefon: "+381 (0)61 69 51 122",
    imeprezime: "NIKOLA RANČIĆ",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    linkedin: "https://www.linkedin.com/in/milan-fara-a73533286/",
    email: "aibg.it@best.rs",
    telefon: "+381 (0)63 78 71 644",
    imeprezime: "MILAN FARA",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    linkedin: "https://www.linkedin.com/in/anastasija-askovic-a05301240/",
    email: "aibg.pub@best.rs",
    telefon: " +381 (0)69 37 10 833",
    imeprezime: "ANASTASIJA AŠKOVIĆ",
    pozicija: "KOORDINATOR ZA DIZAJN",
  },
];
