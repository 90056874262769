// Partneri.js
import React from "react";
import "./Partneri.css"; // Uvezite svoj stil
import { godisnjiData } from "./PartneriData"; // Uvezite podatke o partnerima

const Partneri = () => {
  return (
    <div className="partneri">
      <div className="header-razmak"></div>

      <div className="partneri-container">
        <div className="naslov-centar">
          <h1 className="naslovStranicePartneri">PARTNERI</h1>
        </div>
        {godisnjiData.map((partner, index) => (
          <div className="partner" key={index}>
            <h2 className="partner-naslov">{partner.naslov}</h2>
            <div className="partner-slike">
              {partner.slike.map((slika, slikaIndex) => (
                <a
                  href={slika.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={slikaIndex}
                >
                  <img
                    src={slika.url}
                    alt={`Slika ${slikaIndex + 1}`}
                    className="partner-slika"
                  />
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default Partneri;
