// VestiData.js
import slika1 from "./VestiSlike/OrgTim.jpg";
import metropolitan from "./VestiSlike/metropolitanVest.png";
import metropolitanTakmicenje from "./VestiSlike/metropolitanTakmicenje.jpg";
import meetupPrijave from "./VestiSlike/meetupVest.png";
import aibgPrijave from "./VestiSlike/aibg.png";
import kuceriz1 from "./VestiSlike/kuceriz1.JPG";
import kuceriz2 from "./VestiSlike/kuceriz2.CR2";

// const VestiData = () => {
export const VestiData = [
  {
    naslov: "QCerris je zlatni pokrovitelj AIBG-a 6.0!",
    kratakSadrzaj:
      "Već nekoliko godina u nazad podržavamo takmičenje AIBG Belgrade i možemo da kažemo da nam je čast da smo i ove godine ostvarili saradnju!  Posvećenost takmičara i inovativnost nas inspirišu i jedva čekamo da vidimo kakva revolucionarna rešenja ćete doneti ove godine!",
    ceoSadrzaj:
      "„Naša priča je počela u Čikagu kada je tim tehnoloških veterana i preduzetnika identifikovao prazninu na tržištu.",
    dodatniTekst: (
      <div>
        <p>
          QCerris je rođen kao krajnji produžetak superiornog tehnološkog
          znanja, prilagođenog kratkoročnim i dugoročnim aspiracijama svakog
          klijenta. Osnivači QCerris-a se mogu pohvaliti dugogodišnjim
          praktičnim iskustvom na celom severnoameričkom kontinentu.
        </p>
        <p>
          Do danas smo izrasli u tim koji broji 120 članova i nudimo široki
          spektar stručnjaka specijalizovanih u raznim oblastima kao i cele
          timove posvećene razvijanju softverskih rešenja po zahtevima
          klijenata.
        </p>

        <p>
          Već nekoliko godina u nazad podržavamo takmičenje AIBG Belgrade i
          možemo da kažemo da nam je čast da smo i ove godine ostvarili
          saradnju! Posvećenost takmičara i inovativnost nas inspirišu i jedva
          čekamo da vidimo kakva revolucionarna rešenja ćete doneti ove godine!
        </p>
        <p>Želimo vam puno sreće!”</p>
      </div>
    ),
    slika: kuceriz1,
    index: "QCerris",
    datum: "15.11.2023.",
    autor: "QCerris",
  },
  {
    naslov: "Otvorene su prijave za šesti po redu AIBG!",
    kratakSadrzaj:
      "Šesti put zaredom, u organizaciji BEST-a Beograd, 9. i 10. decembra održava se intenzivno programersko takmičenje AIBG, na već poznatoj lokaciji, Univerzitetu Metropolitan.",
    ceoSadrzaj:
      "Obaveštavamo vas da su prijave za Make IT Learn MeetUp otvorene do 19. novembra!",
    dodatniTekst: (
      <div>
        <p>
          Šesti put zaredom, u organizaciji BEST-a Beograd, 9. i 10. decembra
          održava se intenzivno programersko takmičenje AIBG, na već poznatoj
          lokaciji, Univerzitetu Metropolitan.
        </p>
        <p>
          Artificial Intelligence BattleGround, AIBG je takmičenje koje je
          namenjeno studentima tehničko-tehnoloških, prirodno-matematičkih
          fakulteta i viših škola u Beogradu. Prijave za takmičenje su otvorene
          od 8. do 24. novembra i moguće ih je izvršiti na sajtu AIBG-a. Takođe,
          postoji mogućnost individualne prijave, ali i ekipne (3 do 4 člana).
          Pored novčanih nagrada za prva tri mesta, svojom prijavom za učesće,
          ali i samim učešćem na pomenutom takmičenju, steći ćete dragocena
          iskustva, unaprediti svoje veštine, ali i ostvariti kontakte sa
          vodećim kompanijama iz date oblasti. Ove godine pokrovitelj projekta
          je kompanija QCerris, koja će svojim prisustvom uveličati naše
          takmičenje.
        </p>
      </div>
    ),
    slika: aibgPrijave,
    index: "aibg-prijave",
    datum: "15.11.2023.",
    autor: "Ivona Petković",
  },
  {
    naslov: "Otvorene su prijave za Make IT Learn MeetUp!",
    kratakSadrzaj:
      "Obaveštavamo vas da su prijave za Make IT Learn MeetUp otvorene do 19. novembra!",
    ceoSadrzaj:
      "Obaveštavamo vas da su prijave za Make IT Learn MeetUp otvorene do 19. novembra!",
    dodatniTekst: (
      <div>
        <p>
          Pomenuti MeetUp, koji će se održati 20. novembra od 18 časova na
          Tehnološko-metalurškom fakultetu je propratni sadrž aj AIBG-a,
          intenzivnog studentskog programerskog takmičenja. Make IT Learn MeetUp
          je namenjen svima koji žele da se informišu o novitetima iz sveta
          veštačke inteligencije, ali i da čuju o trenutnim aktuelnostima u toj
          oblasti. Predavanja na teme veštačke inteligencije i mašinskog učenja
          će održati predavači sa dugogodišnjim iskustvom u ovim oblastima,
          predavači sa fakulteta, a takođe i predstavnici kompanija. Više
          informacija o samom MeetUp-u kao i o temama ali i predavačima, možete
          pronaći na našem sajtu, gdje ujedno možete izvršiti i prijavu.
        </p>
      </div>
    ),
    slika: meetupPrijave,
    index: "meetup-prijave",
    datum: "15.11.2023.",
    autor: "Ivona Petković",
  },
  {
    naslov: "Univerzitet Metropolitan, partner 6. po redu AIBG-a",
    kratakSadrzaj:
      "Univerzitet Metropolitan je jedna od vodećih naučno-obrazovnih institucija u Srbiji uokviru koje postoji 8 osnovnih akademskih i 4 master i doktorskih studija.",
    ceoSadrzaj:
      "Univerzitet Metropolitan je jedna od vodećih naučno-obrazovnih institucija u Srbiji u okviru koje postoji 8 osnovnih akademskih i 4 master i doktorskih studija.",
    dodatniTekst: (
      <div>
        <p>
          Pored tradicionalnih, Univerzitet nudi i Online studije, koje
          omogućavaju potpunu fleksibilnost tokom studiranja. Svoju snagu
          Univerzitet gradi na kvalitetu obrazovanja i bliskoj saradnji sa
          poslovnom zajednicom iz oblasti IT-a, menadžmenta i dizajna.
          Univerzitet Metropolitan čine tri fakulteta:
        </p>
        <li>Fakultet informacionih tehnologija</li>
        <li>Fakultet za menadžment</li>
        <li>Fakultet digitalnih umetnosti</li>
        <p>
          Svi studijski programi su kreirani prema potrebama savremene
          industrije i po uzoru na vodeće svetske univerzitete, pružajući
          mogućnost za dalji razvoj i napredak u karijeri. Zahvaljujemo se
          Univerzitetu Metropolitan što treću godinu za redom podržava AIBG
          hakaton tako što obezbeđuje prostor za naše takmičenje.
        </p>
        <a href="https://www.metropolitan.ac.rs">www.metropolitan.ac.rs</a>
      </div>
    ),
    slika: metropolitanTakmicenje,
    index: "metropolitan",
    datum: "15.11.2023.",
    autor: "Univerzitet Metropolitan",
  },
  {
    naslov: "UPOZNAJTE ORGANIZACIONI TIM PROGRAMERSKOG TAKMIČENJA AIBG6.0",
    kratakSadrzaj:
      "Ove godine 7 mladih ljudi i entuzijastičnih ljudi potrudiće se da AIBG 6.0, intenzivni studentski hakaton, prođe u najboljem redu.",
    ceoSadrzaj:
      "Sa zadovoljstvom želimo da vas obavestimo da će se i ove godine organizovati šesti po redu AIBG Belgrade (Artificial Intelligence BattleGround Belgrade), intenzivno programersko takmičenje koje organizuje Udruženje studenata tehnike Evrope, BEST Beograd. Takmičenje je namenjeno svim studentima tehničko-tehnoloških, prirodno-matematičkih fakulteta i studentima viših škola, a održaće se u noći između 9. i 10. decembra.Za organizaciju takmičenja i dodatnog sadržaja koji AIBG kao projekat nudi, zadužen je tim koji čine mladi i ambiciozni ljudi. Njihov zadatak je da zajedno sa volonterima iz organizacije prirede takmičarima uzbudljivo nadmetanje i prijatnu atmosferu.",
    dodatniTekst: (
      <div>
        <p>
          Predstavljamo vam organizacioni tim:Vasilije Gogić – glavni
          organizator, student Fakulteta organizacionih nauka
        </p>
        <p>
          Nikolina Vranković – koordinatorka takmičenja, studentkinja Fakulteta
          organizacionih nauka
        </p>
        <p>
          Milan Fara – koordinator za informacione tehnologije, student
          Fakulteta organizacionih nauka
        </p>
        <p>
          Aleksandra Radovanović – koordinatorka za odnose sa javnošću,
          studentkinja Saobraćajnog fakulteta
        </p>
        <p>
          Dimitrije Siljković – koordinator za odnose sa kompanijama, student
          Fakulteta organizacionih nauka
        </p>
        <p>
          Nikola Rančić – koordinator za logistiku, student Fakulteta
          organizacionih nauka
        </p>
        <p>
          Anastasija Ašković – koordinatorka za dizajn, studentkinja Fakulteta
          organizacionih nauka
        </p>
        <p>
          Više informacija o organizacionom timu možete videti na stranici
          Organizers.
        </p>
      </div>
    ),
    slika: slika1,
    index: "organizacioni-tim-AIBG-6.0",
    datum: "4.10.2023.",
    autor: "Aleksandra Radovanović",
  },
];
