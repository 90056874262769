// Vesti.js
import React from "react";
import { Link } from "react-router-dom";
import "./Vesti.css";
import { VestiData } from "./Novosti/VestiData";

const Vesti = () => {
  // const vesti = VestiData;

  return (
    <div className="pozadina-vesti">
      <div className="vesti-razmak"></div>
      <div className="vesti-container">
        {VestiData.map((vest) => (
          <div className="vest-card" key={vest.index}>
            <img src={vest.slika} alt={vest.naslov} />
            <h2>{vest.naslov}</h2>
            <p>{vest.kratakSadrzaj}</p>
            <Link to={{ pathname: `/vesti/${vest.index}`, state: { vest } }}>
              <button className="dugme-vesti dugme-dno">Prikaži više</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vesti;
