// GalerijaData.js
import slika1 from "./AIBG50-Slike/AIBG5.0-1.JPG";
import slika2 from "./AIBG50-Slike/AIBG5.0-2.JPG";
import slika3 from "./AIBG50-Slike/AIBG5.0-3.JPG";
import slika4 from "./AIBG50-Slike/AIBG5.0-4.JPG";
import slika5 from "./AIBG50-Slike/AIBG5.0-5.JPG";
import slika6 from "./AIBG50-Slike/AIBG5.0-6.JPG";
import slika7 from "./AIBG50-Slike/AIBG5.0-7.JPG";
import slika8 from "./AIBG50-Slike/AIBG5.0-8.JPG";
import slika9 from "./AIBG50-Slike/AIBG5.0-9.JPG";
import slika10 from "./AIBG50-Slike/AIBG5.0-10.JPG";
import slika11 from "./AIBG50-Slike/AIBG5.0-11.JPG";
import slika12 from "./AIBG50-Slike/AIBG5.0-12.JPG";
import slika13 from "./AIBG50-Slike/AIBG5.0-13.JPG";
import slika14 from "./AIBG50-Slike/AIBG5.0-14.JPG";
import slika15 from "./AIBG50-Slike/AIBG5.0-15.JPG";
import slika16 from "./AIBG50-Slike/AIBG5.0-16.JPG";
import slika17 from "./AIBG50-Slike/AIBG5.0-17.JPG";
import slika18 from "./AIBG50-Slike/AIBG5.0-18.JPG";
// import slika19 from "./AIBG50-Slike/AIBG5.0-19.CR2";
import slika20 from "./AIBG50-Slike/AIBG5.0-20.jpg";
// import slika21 from "./AIBG50-Slike/AIBG5.0-21.CR2";
import slika22 from "./AIBG50-Slike/AIBG5.0-22.jpg";
// import slika23 from "./AIBG50-Slike/AIBG5.0-23.CR2";
import slika24 from "./AIBG50-Slike/AIBG5.0-24.jpg";
// import slika25 from "./AIBG50-Slike/AIBG5.0-25.CR2";
import slika26 from "./AIBG50-Slike/AIBG5.0-26.jpg";
// import slika27 from "./AIBG50-Slike/AIBG5.0-27.CR2";
import slika28 from "./AIBG50-Slike/AIBG5.0-28.jpg";
// import slika29 from "./AIBG50-Slike/AIBG5.0-29.CR2";
import slika30 from "./AIBG50-Slike/AIBG5.0-30.JPG";
// import slika31 from "./AIBG50-Slike/AIBG5.0-31.CR2";
import slika32 from "./AIBG50-Slike/AIBG5.0-32.jpg";
import slika33 from "./AIBG50-Slike/AIBG5.0-33.JPG";
import slika34 from "./AIBG50-Slike/AIBG5.0-34.JPG";
// import slika35 from "./AIBG50-Slike/AIBG5.0-35.CR2";
import slika36 from "./AIBG50-Slike/AIBG5.0-36.jpg";
// import slika37 from "./AIBG50-Slike/AIBG5.0-37.CR2";
import slika38 from "./AIBG50-Slike/AIBG5.0-38.jpg";
// import slika39 from "./AIBG50-Slike/AIBG5.0-39.CR2";
import slika40 from "./AIBG50-Slike/AIBG5.0-40.jpg";
import slika41 from "./AIBG50-Slike/AIBG5.0-41.JPG";

const galerijaData = [
  {
    id: 1,
    imgSrc: slika1,
  },
  {
    id: 2,
    imgSrc: slika2,
  },
  {
    id: 3,
    imgSrc: slika3,
  },
  {
    id: 4,
    imgSrc: slika4,
  },
  {
    id: 5,
    imgSrc: slika5,
  },
  {
    id: 6,
    imgSrc: slika6,
  },
  {
    id: 7,
    imgSrc: slika7,
  },
  {
    id: 8,
    imgSrc: slika8,
  },
  {
    id: 9,
    imgSrc: slika9,
  },
  {
    id: 10,
    imgSrc: slika10,
  },
  {
    id: 11,
    imgSrc: slika11,
  },
  {
    id: 12,
    imgSrc: slika12,
  },
  {
    id: 13,
    imgSrc: slika13,
  },
  {
    id: 14,
    imgSrc: slika14,
  },
  {
    id: 15,
    imgSrc: slika15,
  },
  {
    id: 16,
    imgSrc: slika16,
  },
  {
    id: 17,
    imgSrc: slika17,
  },
  {
    id: 18,
    imgSrc: slika18,
  },
  // {
  //   id: 19,
  //   imgSrc: slika19,
  // },
  {
    id: 20,
    imgSrc: slika20,
  },
  // {
  //   id: 21,
  //   imgSrc: slika21,
  // },
  {
    id: 22,
    imgSrc: slika22,
  },
  // {
  //   id: 23,
  //   imgSrc: slika23,
  // },
  {
    id: 24,
    imgSrc: slika24,
  },
  // {
  //   id: 25,
  //   imgSrc: slika25,
  // },
  {
    id: 26,
    imgSrc: slika26,
  },
  // {
  //   id: 27,
  //   imgSrc: slika27,
  // },
  {
    id: 28,
    imgSrc: slika28,
  },
  // {
  //   id: 29,
  //   imgSrc: slika29,
  // },
  {
    id: 30,
    imgSrc: slika30,
  },
  // {
  //   id: 31,
  //   imgSrc: slika31,
  // },
  {
    id: 32,
    imgSrc: slika32,
  },
  {
    id: 33,
    imgSrc: slika33,
  },
  {
    id: 34,
    imgSrc: slika34,
  },
  // {
  //   id: 35,
  //   imgSrc: slika35,
  // },
  {
    id: 36,
    imgSrc: slika36,
  },
  // {
  //   id: 37,
  //   imgSrc: slika37,
  // },
  {
    id: 38,
    imgSrc: slika38,
  },
  // {
  //   id: 39,
  //   imgSrc: slika39,
  // },
  {
    id: 40,
    imgSrc: slika40,
  },
  {
    id: 41,
    imgSrc: slika41,
  },
];

export default galerijaData;
