import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MenuData } from "./MenuData";
import { IoIosArrowDown } from "react-icons/io"; // Importujte ikonicu

import logoAIBG from "../SlikeHome/logo-white.png";
import "./NavbarStyles.css";

class Navbar extends Component {
  state = {
    clicked: false,
    activeSubMenu: null,
  };

  handleClick = () => {
    this.setState({
      clicked: !this.state.clicked,
      activeSubMenu: null,
    });
  };

  handleSubMenuClick = (index) => {
    this.setState((prevState) => ({
      activeSubMenu: prevState.activeSubMenu === index ? null : index,
    }));
  };

  render() {
    return (
      <nav className="NavbarItems">
        <Link to="/">
          <img className="NavLogo" src={logoAIBG} alt="Logo" onClick={this.handleClick}/>
        </Link>
        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"} >
          {MenuData.map((item, index) => {
            const isSubMenuOpen = this.state.activeSubMenu === index;
            const navItemClasses = [
              "nav-item",
              item.hasSubMenu ? "has-submenu" : "",
              isSubMenuOpen ? "submenu-open" : "",
              item.cName || "",
            ]
              .join(" ")
              .trim();
            

            return (
              <li key={index}>
                <div className={navItemClasses} >
                  <Link to={item.url} 
                    className={item.cName} 
                    onClick={() => {
                      item.hasSubMenu && this.handleSubMenuClick(index);
                      !item.hasSubMenu && this.handleClick();
                    }}
                  >
                    {item.title}
                    {item.hasSubMenu && <IoIosArrowDown />}{" "}
                    {/* Dodajte ikonicu za strelicu */}
                  </Link>
                  {item.hasSubMenu && isSubMenuOpen && (
                    <ul className={`submenu`}>
                      {item.subMenu.map((submenuItem, subIndex) => {
                        return (
                          <li key={subIndex} >
                            <div>
                              <Link to={submenuItem.url} onClick={this.handleClick}>
                                {submenuItem.title}
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
