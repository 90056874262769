import MO from "./SlikeStariOrg/MO2022.jpg";
import TOPIC from "./SlikeStariOrg/TOPIC2022.jpg";
import PR from "./SlikeStariOrg/PR2022.jpg";
import FR from "./SlikeStariOrg/FR2022.jpg";
import LOG from "./SlikeStariOrg/LOG2022.jpg";
import IT from "./SlikeStariOrg/IT2022.jpg";
import DESIGN from "./SlikeStariOrg/PUB2022.jpg";

export const OrgTim2022 = [
  {
    naslov5: "Organizacioni tim 2022",
    slikaUrl: MO,
    imeprezime: "Miloš Milaković",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    imeprezime: "Aleksandar Gazikalović",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    imeprezime: "Ana Radović",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    imeprezime: "Nemanja Stanković",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    imeprezime: "Vasilije Gogić",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    imeprezime: "Nikolina Vranković",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    imeprezime: "Igor Ilić",
    pozicija: "KOORDINATOR ZA PUBLIKACIJE",
  },
];
