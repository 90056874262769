// cardData.js
export const cardData = [
  {
    title: "TAKMIČENJE",
    content:
      "AIBG (Artificial Intelligence BattleGround) predstavlja intenzivno studentsko programersko takmičenje timskog karaktera na polju veštačke inteligencije. Takmičari grupisani u 16 timova, od po 3 ili 4 člana, imaju na raspolaganju 20 časova da dizajniraju funkcionalnog inteligentnog agenta (bot-a). ",
    button: "/otakmicenje",
  },
  {
    title: "WARMUP",
    content:
      "WarmUp je niz uvodnih aktivnosti koji će se održati u nedelji pre samog takmičenja.Ovaj dodatni sadržaj namenjen je isključivo takmičarima i njegov cilj je da na adekvatan način uvede takmičare u vikend koji je pred njima. Na online druženju, takmičari će se upoznati i pripremiti za intenzivnu i fer borbu.",
  },
  {
    title: "MEETUP",
    content:
      "Lokalna grupa BEST Beograd i ove godine, u okviru projekta AIBG, pored samog takmičenja, organizuje i edukativni deo Make IT Learn MeetUp. MeetUp predstavlja predavanja na temu veštačke inteligencije i mašinskog učenja, sa ciljem da se studenti upoznaju sa konceptom i trendovima iz ovih sve prisutnijih oblasti.",
    button: "/meetup",
  },
];
