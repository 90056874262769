// PravilaTakmicenja.js
import React from "react";
import "./PravilaTakmicenja.css"; // Uvezite svoj stil
import { pravilaTakmicenjaData } from "./PravilaTakmicenjaData"; // Uvezite podatke o stranicama

const PravilaTakmicenja = () => {
  return (
    <div className="pravila-takmicenja">
      <div className="header-razmak"></div>

      <div className="sections-container">
        <div className="naslov-centar">
          <h1 className="naslovStranice">PRAVILA TAKMIČENJA</h1>
        </div>
        {pravilaTakmicenjaData.map((element, index) => (
          <div className="element" key={index}>
            <h2 className="naslov-elementa">{element.naslov}</h2>
            <p>{element.tekst}</p>
          </div>
        ))}
      </div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default PravilaTakmicenja;
