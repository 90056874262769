import MO from "./SlikeStariOrg/MO2018.jpg";
import TOPIC from "./SlikeStariOrg/TOPIC2018.jpg";
import PR from "./SlikeStariOrg/PR2018.jpg";
import FR from "./SlikeStariOrg/FR2018.jpg";
import LOG from "./SlikeStariOrg/LOG2018.jpg";
import IT from "./SlikeStariOrg/IT2018.jpg";
import DESIGN from "./SlikeStariOrg/PUB2018.jpg";

export const OrgTim2018 = [
  {
    naslov5: "Organizacioni tim 2018",
    slikaUrl: MO,
    imeprezime: "Dragana Šindić",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    imeprezime: "Mina Mitrović",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    imeprezime: "Natalija Srećković",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    imeprezime: "Ana Božić",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    imeprezime: "Marko Slavković",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    imeprezime: "Bojan Miletić",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    imeprezime: "Ana Milenković",
    pozicija: "KOORDINATOR ZA PUBLIKACIJE",
  },
];
