import god1 from "./PartneriSlike/Brose.png";
import god2 from "./PartneriSlike/Cinnamon.png";
import god3 from "./PartneriSlike/FlixBus.png";
import god4 from "./PartneriSlike/Grand.png";
import god5 from "./PartneriSlike/Guarana.png";
import god6 from "./PartneriSlike/Mozzart.png";
import god7 from "./PartneriSlike/Nis-stari.png";
import god8 from "./PartneriSlike/OmMade.png";

import zlatni from "../Home/SlikeHome/qcerris.png";

import rnids from "../Home/SlikeHome/RNIDS.png";

import med1 from "./PartneriSlike/magazin-biznis.png";
import med2 from "./PartneriSlike/national-geographic-srbija.png";
import med3 from "./PartneriSlike/industrija.png";
import med4 from "./PartneriSlike/advertiser.png";
import med5 from "./PartneriSlike/Asset 6.png";
import med6 from "./PartneriSlike/biznisIfinansije.png";
import med7 from "./PartneriSlike/dan u bg.png";
import med8 from "./PartneriSlike/gm.png";
import med9 from "./PartneriSlike/joombooz.png";
import med10 from "./PartneriSlike/naj student.png";
import med11 from "./PartneriSlike/novaekonomija.png";
import med12 from "./PartneriSlike/pcpress.png";
import med13 from "./PartneriSlike/progressive.png";
import med14 from "./PartneriSlike/svet nauke.png";
import med15 from "./PartneriSlike/uninor.png";
import med16 from "./PartneriSlike/youth.png";
import med17 from "./PartneriSlike/SBU-Poslovi logo2.png";
import med18 from "./PartneriSlike/omladinskenovine.jpg";
import med19 from "./PartneriSlike/Centar za razvoj karijere.png";
import med20 from "./PartneriSlike/pr-objave.png";
import med21 from "./PartneriSlike/studentirs-logo-black.png";
import med22 from "./PartneriSlike/Telegraf.rs_logo.jpg";
import med23 from "./PartneriSlike/Kuca-dobrih-vesti.png";
import med24 from "./PartneriSlike/radio bg202.png";
import med25 from "./PartneriSlike/UNA NOVI LOGO vektor plavi.png";
import med26 from "./PartneriSlike/online_ekapija_logo_sr.png";

import nag1 from "./PartneriSlike/Kampster.svg";
import nag2 from "./PartneriSlike/ESCAPE GAME.png";
import nag3 from "./PartneriSlike/Javni akvarijum.png";
import nag4 from "./PartneriSlike/mmClean.jpg";
import nag5 from "./PartneriSlike/Skakaonica-01.png";
import nag6 from "./PartneriSlike/ponesi.ceger.png";
import nag7 from "./PartneriSlike/slatkoca.JPG";
import nag8 from "./PartneriSlike/Museum of the Paranormal logo.png";
import nag9 from "./PartneriSlike/EsencijalnaUlja.png";
import nag10 from "./PartneriSlike/zlobex.png";
import nag11 from "./PartneriSlike/Art&Fun.png";
import nag12 from "./PartneriSlike/Anky.jpeg";
import nag13 from "./PartneriSlike/Zabac logo-1.png";
import nag14 from "./PartneriSlike/godva.png";
import nag15 from "./PartneriSlike/pozoriste slavija.jpg";

import pod1 from "../Home/SlikeHome/Metropolitan.png";
import pod2 from "./PartneriSlike/cpn.png";
import pod3 from "./PartneriSlike/ivi.png";
import pod4 from "./PartneriSlike/ict.png";
import pod5 from "./PartneriSlike/viser-logo.png";
import pod6 from "./PartneriSlike/ETF.png";
import pod7 from "./PartneriSlike/RAF.png";
import pod8 from "./PartneriSlike/FON.png";
import pod9 from "./PartneriSlike/MatematickiFakultet.png";
import pod10 from "./PartneriSlike/Masinski.png";
import pod11 from "./PartneriSlike/tmf.jpeg";

export const godisnjiData = [
  {
    naslov: "ZLATNI POKROVITELJ​",
    slike: [{ url: zlatni, link: "https://qcerris.com" }],
  },
  {
    naslov: "SPONZORI PROJEKTA​",
    slike: [{ url: rnids, link: "https://www.rnids.rs/en" }],
  },
  {
    naslov: "PARTNERI PROJEKTA​",
    slike: [{ url: pod1, link: "https://www.metropolitan.ac.rs" }],
  },
  // {
  //   naslov: "SREBRNI POKROVITELJ ​",
  //   slike: [{ url: zlatni }],
  // },
  // {
  //   naslov: "PRIJATELJI PROJEKTA ​",
  //   slike: [{ url: zlatni }],
  // },
  {
    naslov: "GODIŠNJI POKROVITELJI",
    slike: [
      {
        url: god1,
        link: "https://www.brose.com/de-en/",
      },
      {
        url: god2,
        link: "https://www.cinnamon.agency/",
      },
      {
        url: god3,
        link: "https://global.flixbus.com/",
      },
      {
        url: god4,
        link: "https://www.grandkafa.rs/",
      },
      {
        url: god5,
        link: "https://knjaz.rs",
      },
      {
        url: god6,
        link: "https://www.mozzartbet.com/sr#/",
      },
      {
        url: god7,
        link: "https://www.nis.rs/en/",
      },
      {
        url: god8,
        link: "https://www.ommade.rs/",
      },
    ],
  },
  {
    naslov: "MEDIJSKI POKROVITELJ ​",
    slike: [
      { url: med1, link: "https://magazinbiznis.rs" },
      { url: med2, link: "https://nationalgeographic.rs" },
      { url: med3, link: "https://www.industrija.rs" },
      { url: med4, link: "https://www.advertiser-serbia.com" },
      { url: med5, link: "https://sga.rs/en/" },
      { url: med6, link: "https://bif.rs" },
      { url: med25, link: "https://unaworld.tv" },
      { url: med26, link: "https://www.ekapija.com" },
      { url: med7, link: "https://www.danubeogradu.rs" },
      { url: med8, link: "https://gmbusiness.biz" },
      { url: med9, link: "https://www.joombooz.com" },
      { url: med10, link: "https://www.startuj.com" },
      { url: med11, link: "https://novaekonomija.rs" },
      { url: med12, link: "https://pcpress.rs" },
      { url: med13, link: "https://progressivemagazin.rs/online/" },
      { url: med24, link: "https://www.rts.rs/lat/radio/beograd-202.html" },
      { url: med14, link: "https://www.svetnauke.org" },
      { url: med15, link: "https://uninor.rs" },
      {
        url: med16,
        link: "https://www.instagram.com/esencijalna.ulja.srbija/",
      },
      { url: med17, link: "https://www.sbu-zadruga.rs" },
      { url: med18, link: "https://omladinskenovine.rs" },
      { url: med19, link: "http://www.razvojkarijere.bg.ac.rs/" },
      { url: med20, link: "https://www.probjave.com/" },
      { url: med21, link: "https://studenti.rs/" },
      { url: med22, link: "https://www.telegraf.rs/" },
      { url: med23, link: "https://dobrevesti.rs/" },
    ],
  },
  {
    naslov: "NAGRADNI POKROVITELJI",
    slike: [
      { url: nag1, link: "https://www.thecampster.com/rs/" },
      { url: nag2, link: "https://escapegame.rs" },
      { url: nag3, link: "https://javniakvarijum.rs" },
      { url: nag4, link: "https://www.instagram.com/mmclean_beograd/" },
      { url: nag5, link: "https://www.skakaonica.rs" },
      { url: nag6, link: "https://www.instagram.com/ponesi.ceger/" },
      { url: nag7, link: "https://www.instagram.com/slatkoca_store/" },
      { url: nag8, link: "https://www.museumoftheparanormal.rs" },
      { url: nag9 },
      { url: nag10, link: "https://www.zlobex.com" },
      { url: nag11, link: "https://artfun.rs" },
      { url: nag12, link: "https://www.instagram.com/anky.rs/" },
      { url: nag13, link: "https://zabac.rs" },
      { url: nag14, link: "https://go2travelling.net" },
      { url: nag15, link: "https://www.pozoriste-slavija.co.rs" },
    ],
  },
  {
    naslov: "PROJEKAT PODRŽALI​",
    slike: [
      { url: pod2, link: "https://www.cpn.edu.rs/" },
      { url: pod3, link: "https://www.ivi.ac.rs/" },
      { url: pod8, link: "https://fon.bg.ac.rs" },
      { url: pod7, link: "https://raf.edu.rs" },
      { url: pod6, link: "https://www.etf.bg.ac.rs" },
      { url: pod9, link: "http://www.matf.bg.ac.rs" },
      { url: pod10, link: "https://www.mas.bg.ac.rs" },
      { url: pod11, link: "http://www.tmf.bg.ac.rs/" },
      { url: pod4, link: "https://www.ict.edu.rs/" },
      { url: pod5, link: "https://www.viser.edu.rs/" },
    ],
  },
  // {
  //   naslov: "ROBNI POKROVITELJI ​",
  //   slike: [{ url: zlatni }],
  // },
  // {
  //   naslov: "PROJEKAT PODRŽALI​ ​",
  //   slike: [{ url: zlatni }],
  // },
];
