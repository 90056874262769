import pokrovitelj1 from "../SlikeHome/qcerris.png";
import pokrovitelj2 from "../SlikeHome/Metropolitan.png";
import pokrovitelj3 from "../SlikeHome/RNIDS.png";
// import pokrovitelj6 from "../SlikeHome/";
// import pokrovitelj7 from "../SlikeHome/";
// import pokrovitelj8 from "../SlikeHome/";
// import pokrovitelj9 from "../SlikeHome/";
// import pokrovitelj10 from "../SlikeHome/";
// import pokrovitelj11 from "../SlikeHome/";

export const PokroviteljiData = [
  {
    imageUrl: pokrovitelj1,
  },
  {
    imageUrl: pokrovitelj2,
  },
  {
    imageUrl: pokrovitelj3,
  },
];
