import React from "react";
import "./Card.css";

const Card = ({ title, content, button }) => {
  return (
    <div className="card">
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-text">{content}</p>
        <br />
        {button && (
          <a href={button} className="button-text">
            Pročitaj više
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
