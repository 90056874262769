import MO from "./SlikeStariOrg/MO2019.jpg";
import TOPIC from "./SlikeStariOrg/TOPIC2019.jpg";
import PR from "./SlikeStariOrg/PR2019.jpg";
import FR from "./SlikeStariOrg/FR2019.jpg";
import LOG from "./SlikeStariOrg/LOG2019.jpg";
import IT from "./SlikeStariOrg/IT2019.jpg";
import DESIGN from "./SlikeStariOrg/PUB2019.jpg";

export const OrgTim2019 = [
  {
    naslov5: "Organizacioni tim 2019",
    slikaUrl: MO,
    imeprezime: "Olivera Krnjajić",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    imeprezime: "Anica Tešić",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    imeprezime: "Uroš Nedeljković",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    imeprezime: "Milena Kašić",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    imeprezime: "Marija Dujović",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    imeprezime: "Darko Zorić",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    imeprezime: "Nikolina Rašović",
    pozicija: "KOORDINATOR ZA PUBLIKACIJE",
  },
];
