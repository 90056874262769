// VestiData.js
import AI1 from "./EdukutakSlike/ai-technology-microchip-background-digital-transformation-concept.jpg";
import AI2 from "./EdukutakSlike/robot-hand-finger-ai-background-technology-graphics.jpg";

import AImed1 from "./EdukutakSlike/3d-rendering-biorobots-concept.jpg";
import AImed2 from "./EdukutakSlike/robot-hand-finger-ai-background-technology-graphics.jpg";

//!!!!!! klase podvuceni i italic se nalaze u vesti.css

export const EdukutakData = [
  {
    naslov: "Primena veštačke inteligencije u medicini",
    kratakSadrzaj:
      "Svedoci smo da AI polako ali sigurno osvaja svaku ljudsku delatnost, pa tako, korak po korak osvaja i medicinu. U dijagnostici različitih oboljenja je nepogrešiva, ali se postavlja pitanje da li će u nekom momentu zameniti lekara?",
    ceoSadrzaj: (
      <div>
        {" "}
        <p>
          Ubrzanim razvojem informacionih tehnologija u poslednjih nekoliko
          godina, zamena medicinskih uređaja računarima je omogućila bolju
          povezanost stručnjaka, znanja i tehnologija u cilju poboljšanja
          zdravlja pacijenata. Iako mnogi iskusni lekari nisu oduševljeni
          upotrebom AI-a u bolnicama, naučnici u laboratorijama širom sveta
          neprestano istražuju mogućnosti primene nove tehnologije. Danas su
          dostupne analize koje su nekada bile nemoguće, zahvaljujući računarima
          i računarskim sistemima na raspolaganju su nove terapisjke procedure,
          životni vek je produžen i zdravstveno stanje pacijenata dosta
          poboljšano.
        </p>
        <p>
          Svakog dana, svesno ili nesvesno, pratimo koliko smo koraka prešli, da
          li smo se približili čuvenim 10000 ili prešli brojku. Zahvaljujući
          razvijenim sistemima, koji se često nadograđuju, pametni telefoni i
          satovi sa senzorima će u skorijoj budućnosti biti od sve veće pomoći
          zdravstvu.
        </p>
      </div>
    ),
    dodatniTekst: (
      <div>
        <p>
          Takođe, AI se koristi u oblasti virtuelnih asistenata za negu, jer od
          kontakta sa pacijentima do njihovog savetovanja za najefikasniji način
          nege, virtuelni asistenti za negu mogu pomoći zdravstvenoj industriji
          u uštedi i do 20 milijardi dolara godišnje. Ako vam je potreban termin
          kod psihoterapeuta AI može privremeno da zameni psihijatra ili
          psihoterapeuta putem virtuelnih asistenata.
        </p>
        <p>
          Po svemu sudeći, veliki razvoj tehnologije će u budućnosti omogućiti
          značajno poboljšanje terapijskih ishoda i dovesti do smanjenja grešaka
          u lečenju, sve u cilju poboljšanja zdravlja ljudi i kvaliteta života.
        </p>
      </div>
    ),
    slika: AImed1,
    datum: "10.11.2023.",
    autor: "Milica Paunić",
    index: "AI-u-medicini",
  },
  {
    naslov: "Veštačka inteligencija (Artificial Intelligence – AI)",
    ceoSadrzaj: (
      <div>
        <p>
          Kao što već znamo AI je veoma uznapredovala, ima pristup raznim
          informacija i čak može da nam odgovori na stručna pitanja iz naučnih
          oblasti. Pojedini svetski uticajni preduzetnici, naučnici i programeri
          apelovali su na laboratorije veštačke inteligencije da obustave svoj
          rad na neko određeno vreme, kako bi se osvrnuli na svoj dosadašnji rad
          i sagledali sve posledice konstantnih ažuriranja novijih generacija
          AI-a. Vlada mišljenje među stručnim licima da se AI laboratorije
          neprestano međusobno takmiče ne osvrćući se kako njihov rad utiče na
          čovečanstvo. Osim pauze u razvoju i primeni AI-a, takođe pozivaju
          vlade da se uključe u proceduru i ako je potrebno, spreče dalji
          razvoj.
        </p>
        <p className="italic">
          „AI laboratorije i nezavisni eksperti treba da iskoriste ovu pauzu
          kako bi implementirali skup dogovorenih bezbednosnih protokola za
          dizajn i razvoj naprednih veštačkih inteligencija koje će rigorozno
          nadgledati nezavisni spoljni stručnjaci. Ovo zapravo i nije pauza u
          razvoju veštačke inteligencije, već samo korak unazad kako bi se
          udaljili od opasne trke ka sve većim nepredvidivim modelima sa
          dodatnim sposobnostima" – grupa Future of Life
        </p>
      </div>
    ),
    kratakSadrzaj:
      "Veštačka inteligencija (Artificial Intelligence – AI) je neprestano jedna od najaktuelnijih tema današnjice među stručnjacima raznih oblasti nauke i tehnologije, kako zbog svojih benefita koje nam pruža svakodnevno, tako i kao potencijalna opasnost za mnoga radna mesta. ",
    dodatniTekst: (
      <div>
        <p className="podvuceno">
          Da li AI ima širok spektar mogućnosti, znanja i veština kao i ljudski
          um?
        </p>
        <p>
          {" "}
          Za sada ne postoje tačni podaci koji nam daju informaciju da nas je
          veštačka inteligencija nadmašila. Sa druge strane pojedine uspešne
          kompanije sveta se hvale kako uskoro neće zapošljavati ljude kao
          grafičke dizajnere već bi njihov posao obavljavi botovi veštačke
          inteligencije. Zbog ovakvih izjava strah među ljudima je sasvim
          razumljiv i opravdan. Bez obzira na to, ljudi ne trebalo da klonu.
          Koliko god da AI napreduje nikada neće moći da prevaziđe ljudsku
          emociju, jer je ona ni nema. Jednostvano, za mnoga radna mesta je ona
          neizostavna. Nije dovoljno samo puko znanje kako bi se obavio neki
          posao. Emocija je ono što čoveka izdvaja kao individuu.
        </p>
        <p className="podvuceno">
          Da li i u kojoj meri može da odmeni rad programera?
        </p>
        <p>
          S obzirom na današnju situaciju, pretpostavlja se da ne može. Ipak je
          AI isprogramirao programer i na njenom usavršavanju konstantno rade
          programeri. Prosto na osnovu zdravog razuma ona ne može i ne sme da
          zameni programere. Ipak veštačka inteligencija može da zameni čoveka u
          mnogim programerskim zahtevima.
        </p>
      </div>
    ),
    slika: AI1,
    index: "AI",
    datum: "10.11.2023.",
    autor: "Natalija Okrajinov ",
  },
];
