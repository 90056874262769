import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Home/Nav/Navbar";
import Otakmicenju from "./Components/Takmicenje/Otakmicenju/Otakmicenju";
import PravilaTakmicenja from "./Components/Takmicenje/PravilaTakmicenja/PravilaTakmicenja";
import Meetup from "./Components/Meetup/Meetup";
import Prijava from "./Components/Prijava/Prijava";
import Partneri from "./Components/Partneri/Partneri";
import Organizatori from "./Components/Organizatori/Organizatori";
import Galerije from "./Components/Galerija/Galerije";
import Kontakt from "./Components/Kontakt/Kontakt";
import StariOrganizatori from "./Components/Organizatori/StariOrganizatori";
import Blogpost from "./Components/Vesti/Vesti";
import Edupost from "./Components/Vesti/Edukutak/Edukutak";
import AIBG10 from "./Components/Galerija/AIBG1.0/AIBG1.0";
import AIBG20 from "./Components/Galerija/AIBG2.0/AIBG2.0";
import AIBG30 from "./Components/Galerija/AIBG3.0/AIBG3.0";
import AIBG40 from "./Components/Galerija/AIBG4.0/AIBG4.0";
import AIBG50 from "./Components/Galerija/AIBG5.0/AIBG5.0";
import MeetupPrijava from "./Components/Meetup/MeetupPrijava";
import PojedinacneVesti from "./Components/Vesti/PojedinacneVesti";
import { VestiData } from "./Components/Vesti/Novosti/VestiData";
import { EdukutakData } from "./Components/Vesti/Edukutak/EdukutakData";
import PrijavaLog from "./Components/PrijaveTimova/PrijavaLog";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>
                    Artificial Intelligence BattleGround - AIBG Belgrade
                  </title>
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/otakmicenje"
            element={
              <>
                <Helmet>
                  <title>O takmičenju - AIBG Belgrade</title>
                </Helmet>
                <Otakmicenju />
              </>
            }
          />
          <Route
            path="/pravila"
            element={
              <>
                <Helmet>
                  <title>Pravila takmičenja - AIBG Belgrade</title>
                </Helmet>
                <PravilaTakmicenja />
              </>
            }
          />
          <Route
            path="/meetup"
            element={
              <>
                <Helmet>
                  <title>Meetup - AIBG Belgrade</title>
                </Helmet>
                <Meetup />
              </>
            }
          />
          {/* <Route
            path="/prijava"
            element={
              <>
                <Helmet>
                  <title>Prijava - AIBG Belgrade</title>
                </Helmet>
                <Prijava />
              </>
            }
          /> */}
          <Route
            path="/vesti"
            element={
              <>
                <Helmet>
                  <title>Vesti - AIBG Belgrade</title>
                </Helmet>
                <Blogpost />
              </>
            }
          />
          <Route
            path="/edukutak"
            element={
              <>
                <Helmet>
                  <title>Edukutak - AIBG Belgrade</title>
                </Helmet>
                <Edupost />
              </>
            }
          />
          {/* <Route
            path="/galerije"
            element={
              <Galerije
                Galerije1={AIBG10}
                Naslov={"AIBG 1.0"}
                title="Galerija 1.0 - AIBG Belgrade"
              />
            }
          >
            <Route path="/galerije/AIBG1.0" element={<AIBG10 />} />
          </Route> */}
          {/* <Route
            path="/galerije"
            element={
              <Galerije
                Galerije1={AIBG20}
                Naslov={"AIBG 2.0"}
                title="Galerija 2.0 - AIBG Belgrade"
              />
            }
          >
            <Route path="/galerije/AIBG2.0" element={<AIBG20 />} />
          </Route> */}
          {/* <Route
            path="/galerije"
            element={
              <Galerije
                Galerije1={AIBG30}
                Naslov={"AIBG 3.0"}
                title="Galerija 3.0 - AIBG Belgrade"
              />
            }
          >
            <Route path="/galerije/AIBG3.0" element={<AIBG30 />} />
          </Route> */}
          {/* <Route
            path="/galerije"
            element={
              <Galerije
                Galerije1={AIBG40}
                Naslov={"AIBG 4.0"}
                title="Galerija 4.0 - AIBG Belgrade"
              />
            }
          >
            <Route path="/galerije/AIBG4.0" element={<AIBG40 />} />
          </Route> */}
          <Route
            path="/galerije"
            element={
              <Galerije
                Galerije1={AIBG50}
                Naslov={"AIBG 5.0"}
                title="Galerija 5.0 - AIBG Belgrade"
              />
            }
          >
            <Route path="/galerije/AIBG5.0" element={<AIBG50 />} />
          </Route>

          <Route
            path="/partneri"
            element={
              <>
                <Helmet>
                  <title>Partneri - AIBG Belgrade</title>
                </Helmet>
                <Partneri />
              </>
            }
          />
          <Route
            path="/organizatori"
            element={
              <>
                <Helmet>
                  <title>Organizatori - AIBG Belgrade</title>
                </Helmet>
                <Organizatori />
              </>
            }
          />
          <Route
            path="/kontakt"
            element={
              <>
                <Helmet>
                  <title>Kontakt - AIBG Belgrade</title>
                </Helmet>
                <Kontakt />
              </>
            }
          />
          <Route
            path="/stari-organizatori"
            element={
              <>
                <Helmet>
                  <title>Prethodni organizacioni timovi - AIBG Belgrade</title>
                </Helmet>
                <StariOrganizatori />
              </>
            }
          />
          {/* <Route path="/meetup-prijava" element={<MeetupPrijava />} /> */}

          {VestiData.map((vest) => (
            <Route
              path={`/vesti/${vest.index}`}
              element={<PojedinacneVesti pojedinacnaVest={vest} />}
            />
          ))}

          {EdukutakData.map((edukutak) => (
            <Route
              path={`/edukutak/${edukutak.index}`}
              element={<PojedinacneVesti pojedinacnaVest={edukutak} />}
            />
          ))}

          <Route
            path="/logiPrijava"
            element={
              <>
                <Helmet>
                  <title>Prijava za LOG-i tim - AIBG Belgrade</title>
                </Helmet>
                <PrijavaLog />
              </>
            }
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
