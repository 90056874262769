import ljudi from "../Otakmicenju/SlikeOtakmicenju/ljudi-01.png";
import lokacija from "../Otakmicenju/SlikeOtakmicenju/saljiMiLokaciju5-01-300x300.png";
import kalendar from "../Otakmicenju/SlikeOtakmicenju/kalendar5-01-300x300.png";
import dzojstik from "../Otakmicenju/SlikeOtakmicenju/ejZivotePokvareni-01-300x300.png";
import macevi from "../Otakmicenju/SlikeOtakmicenju/macevi5-01-300x300.png";
import trofej from "../Otakmicenju/SlikeOtakmicenju/pehar5-01-300x300.png";

export const otakmicenjuData = [
  {
    imageUrl: ljudi,
    text: "Takmičenje je namenjeno studentima tehničko-tehnoloških i prirodno-matematičkih fakulteta u Beogradu, starosti od 18 do 26 godina. Učešće je besplatno.",
  },
  {
    imageUrl: lokacija,
    text: "Za celokupno vreme trajanja takmičenja, takmičari će biti smešteni u zajedničkom prostoru. Hrana i piće su obezbeđeni.",
  },
  {
    imageUrl: kalendar,
    text: "Takmičenje se održava 9. i 10. decembra. Program počinje u subotu, 9. decembra u popodnevnim časovima. Izrada rešenja zadatka traje 20 časova. ",
  },
  {
    imageUrl: dzojstik,
    text: "Zadatak koji će timovi imati jeste da na osnovu zadate video igre dizajniraju inteligentnog agenta koji će samostalno igrati igru, a kasnije i da odmeri snage sa agentima drugih timova u dve faze:",
  },
  {
    imageUrl: macevi,
    text: "U grupnoj fazi, gde iz četiri grupe od po četiri tima izlazi po jedan pobednički tim koji nastavlja takmičenje. U finalnoj borbi će se pobednici grupne faze međusobno boriti za prva tri mesta.",
  },
  {
    imageUrl: trofej,
    text: "Pobednička tri tima očekuju novčane nagrada u iznosu od 100.000 RSD, 50.000 RSD i 25.000 RSD.",
  },
];
