import React from "react";
import "./Card.css";

const Pokrovitelji = ({ imageUrl }) => {
  return (
    <div className="pokroviteljiCard">
      <div className="card-content">
        <img src={imageUrl} alt="Card" className="card-image" />
      </div>
    </div>
  );
};

export default Pokrovitelji;
