import React from "react";

const OrganizatoriList = (props) => {
  // Pristupamo naslovu iz prvog organizatora u organizatoriData
  const OrgTim = props.OrgTim;
  const ikonice = props.ikonice;
  const prviOrganizatorNaslov = OrgTim[0].naslov5;

  return (
    <div className="orgpom">
      <div className="organizatori-container">
        <div className="naslov-centar">
          <h1 className="naslovStranice">{prviOrganizatorNaslov}</h1>
        </div>
        {OrgTim.map((organizator, index) => (
          <div className="organizator" key={index}>
            <img src={organizator.slikaUrl} className="slika" />
            <div className="tekst-container">
              <p className="ime-prezime">{organizator.imeprezime}</p>
              <p className="pozicija">{organizator.pozicija}</p>
              {ikonice && ( // Dodajte uslov za prikazivanje
                <div className="linkmail">
                  <div className="linkedin">
                    <a
                      href={organizator.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                  <div className="email">
                    <a href={`mailto:${organizator.email}`}>
                      <i className="far fa-envelope"></i>
                    </a>
                  </div>
                </div>
              )}
              {ikonice && ( // Dodajte uslov za prikazivanje
                <div className="telefon">
                  <i className="fas fa-phone"></i>
                  <p>{organizator.telefon}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrganizatoriList;
