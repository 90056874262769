import medalja1 from "../SlikeHome/Medalje-1.png";
import medalja2 from "../SlikeHome/Medalje-2.png";
import medalja3 from "../SlikeHome/Medalje-3.png";

export const cardNagradeData = [
  {
    title: "PRVO MESTO",
    content: "100.000 RSD",
    imageUrl: medalja1,
  },
  {
    title: "DRUGO MESTO",
    content: "50.000 RSD",
    imageUrl: medalja2,
  },
  {
    title: "TREĆE MESTO",
    content: "25.000 RSD",
    imageUrl: medalja3,
  },
];
