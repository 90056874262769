import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import "./Footer.css"; // Uvezite CSS datoteku

export default function Footer() {
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    // Funkcija koja će se pozvati kad dođe do promene skrolanja
    function handleScroll() {
      // Visina prozora pregledača
      const windowHeight = window.innerHeight;

      // Visina dokumenta
      const documentHeight = document.body.clientHeight;

      // Pozicija skrolanja
      const scrollPosition =
        window.scrollY || window.pageYOffset || document.body.scrollTop || 0;

      // Visina footera
      const footerHeight = document.querySelector(".footer").offsetHeight;

      // Ako je visina dokumenta - pozicija skrolanja - visina prozora manja od visine footera,
      // tada pokažite footer, inače sakrijte footer.
      if (documentHeight - scrollPosition - windowHeight < footerHeight) {
        setIsFooterVisible(true);
      } else {
        setIsFooterVisible(false);
      }
    }

    // Dodajemo "slušača" za promene skrolanja
    window.addEventListener("scroll", handleScroll);

    // Pozivamo handleScroll() kako bismo postavili početno stanje footera
    handleScroll();

    // Uklanjanje event listener-a kad se komponenta unmount-uje
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`footer ${isFooterVisible ? "visible" : ""}`}>
      <div className="footer-content">
        <div className="social-icons">
          <a href="https://www.facebook.com/best.beograd/">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/best_belgrade/">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/best-belgrade/">
            <FaLinkedin />
          </a>
        </div>
        <div className="footer-text">
          <p>
            &copy; AIBG Belgrade © 2023 • Made with <FaHeart className="srce" />{" "}
            by BEST Belgrade
          </p>
        </div>
      </div>
    </div>
  );
}
