import MO from "./SlikeStariOrg/MO2020.jpg";
import TOPIC from "./SlikeStariOrg/TOPIC2020.jpg";
import PR from "./SlikeStariOrg/PR2020.jpg";
import FR from "./SlikeStariOrg/FR2020.jpg";
import LOG from "./SlikeStariOrg/LOG2020.jpg";
import IT from "./SlikeStariOrg/IT2020.jpg";
import DESIGN from "./SlikeStariOrg/PUB2020.jpg";

export const OrgTim2020 = [
  {
    naslov5: "Organizacioni tim 2020",
    slikaUrl: MO,
    imeprezime: "Bogdan Kostić",
    pozicija: "GLAVNI ORGANIZATOR",
  },
  {
    slikaUrl: TOPIC,
    imeprezime: "Milica Sudar",
    pozicija: "KOORDINATOR TAKMIČENJA",
  },
  {
    slikaUrl: PR,
    imeprezime: "Anđela Arsenijević",
    pozicija: "KOORDINATOR ZA ODNOSE SA JAVNOŠĆU",
  },
  {
    slikaUrl: FR,
    imeprezime: "Kristina Mitrović",
    pozicija: "KOORDINATOR ZA ODNOSE SA KOMPANIJAMA",
  },
  {
    slikaUrl: LOG,
    imeprezime: "Milica Galjak",
    pozicija: "KOORDINATOR ZA LOGISTIKU",
  },
  {
    slikaUrl: IT,
    imeprezime: "Aleksa Marković",
    pozicija: "KOORDINATOR ZA INFORMACIONE TEHNOLOGIJE",
  },
  {
    slikaUrl: DESIGN,
    imeprezime: "Anđela Ristivojević",
    pozicija: "KOORDINATOR ZA PUBLIKACIJE",
  },
];
