import { FaTimes } from "react-icons/fa";

const GalerijeList = (props) => {
  const GALERIJA = props.GALERIJA;
  const model = props.model;
  const tempimgSrc = props.tempimgSrc;
  const getImg = props.getImg;
  const setModel = props.setModel;
  const Naslov = props.Naslov;
  return (
    <div className="galerija-list">
      <div className="footer-razmak"></div>
      <div className="elementi-container">
        <div className="naslov-centar">
          <h1 className="naslovStranice">{Naslov}</h1>{" "}
        </div>
        <div className={model ? "model open" : "model"}>
          <img src={tempimgSrc} alt="Gallery" />
          <FaTimes onClick={() => setModel(false)} />
        </div>
        <div className="galerija">
          {GALERIJA.map((item, index) => {
            return (
              <div
                className="slike"
                key={index}
                onClick={() => getImg(item.imgSrc)}
              >
                <img
                  src={item.imgSrc}
                  alt={`Image ${item.id}`}
                  style={{ width: "100%" }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default GalerijeList;
