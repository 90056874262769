import React from "react";
import { Link } from "react-router-dom"; // Importujte Link komponentu
import "./Organizatori.css";
import { organizatoriData } from "./OrganizatoriData";
import OrganizatoriList from "./OrganizatoriList";

const ikonice = true;

const Organizatori = () => {
  return (
    <div className="organizatori">
      <div className="header-razmak"></div>

      <div className="flex-container">
        <div className="div-gornji">
          <h2 className="naslov-div">BEST</h2>
          <p className="tekst">
            BEST (Board of European Students of Technology) je neprofitna,
            apolitična i nevladina evropska studentska volonterska organizacija
            koja obezbeđuje komunikaciju i saradnju studenata
            tehničko-tehnoloških i prirodno-matematičkih fakulteta širom Evrope.
            BEST je na međunarodnom nivou osnovan 1989. godine i organizuje
            preko 100 različitih događaja godišnje. Na taj način povezuje više
            od 5000 evropskih studenata tehnike koji kroz projekte raznovrsnog
            karaktera stiču nova iskustva, veštine i neformalno obrazovanje.
            Danas, sa 85 lokalnih grupa u 30 zemlji Evrope, BEST je druga po
            veličini studentska organizacija u Evropi.
          </p>
        </div>
        <div className="div-donji">
          <h2 className="naslov-div">BEST Beograd</h2>
          <p className="tekst">
            Lokalna grupa BEST Beograd osnovana je novembra 2003. od strane
            entuzijastičnih studenata koji su želeli da dovedu Evropu u Beograd.
            Danas, sa oko 250 aktivnih članova sa 18 različitih fakulteta, BEST
            Beograd je jedna od najprepoznatljivijih studentskih organizacija na
            Univerzitetu u Beogradu. Ambiciozni, mladi članovi rade na
            organizovanju stručnih kurseva i kulturnih razmena, inženjerskih i
            programerskih takmičenja i seminara.
          </p>
          <a href="https://best.rs/" className="dugme">
            Vise o nama
          </a>
        </div>
      </div>
      <div className="footer-razmak"></div>
      <OrganizatoriList OrgTim={organizatoriData} ikonice={ikonice} />
      <div className="staritimovilink">
        {/* Link ka stranici StariOrganizatori.js */}
        <Link to="/stari-organizatori" className="dugme2">
          Prethodni organizacioni timovi
        </Link>
      </div>
      <div className="footer-razmak"></div>
    </div>
  );
};

export default Organizatori;
